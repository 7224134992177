import React from "react";
import Box from "@mui/material/Box";
import { Divider, Grid, Link, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TextUppercaseAndSpacing } from "./TextUppercaseAndSpacing";
import { ChancenbuddyLettering } from "../ChancenbuddyLettering";
import LinkColorless from "../LinkColorless";
import { useLogto } from "@logto/react";
import { useSnackbar } from "notistack";
import LOGO_CLAUSSEN_SIMON_STIFTUNG from "../../../src/assets/partner-logos/logo-CSS_3_zeilig_Farbe-min.jpg";
import LOGO_STEUERBERATUNG_SCHWARZ from "../../../src/assets/partner-logos/logo-steuerberatung-schwarz-min.png";
import LOGO_KIPITAN from "../../../src/assets/partner-logos/kipitan-logo.svg";

export function Footer(props: { isReducedFooter?: boolean }) {
  const { signIn } = useLogto();
  const { enqueueSnackbar } = useSnackbar();
  const { isReducedFooter } = props;

  async function handleLoginClicked() {
    try {
      await signIn(
        `${window.location.protocol}//${window.location.host}/callback`
      );
    } catch (error) {
      console.error("Error on forwarding to login", error);
      enqueueSnackbar(
        `Weiterleitung zur Anmeldung nicht erfolgreich: ${error}`,
        { variant: "error" }
      );
    }
  }

  return (
    <Box
      sx={{
        width: "90%",
        p: 2,
        m: 2,
        borderRadius: "1.5rem",
      }}
    >
      <Stack direction="row" sx={{ flexWrap: "wrap", width: "100%" }} m={5}>
        <Stack spacing={2} width={"100%"}>
          {!isReducedFooter && <ChancenbuddyLettering />}

          <Stack width={"100%"} sx={{ flexWrap: "wrap" }} spacing={2}>
            <Stack direction={"row"} sx={{ flexWrap: "wrap" }}>
              {!isReducedFooter && (
                <>
                  <Stack spacing={2} pb={2}>
                    <Box>
                      <Typography>Löhergraben 6</Typography>
                      <Typography>52064 Aachen</Typography>
                    </Box>
                    <Box>
                      <Typography>Amtsgericht Aachen HRB 27548</Typography>
                      <Typography>
                        Geschäftsführung: Clara Bleckmann | Yannick Folsche
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack
                    sx={{ pl: { sm: 0, md: 15 } }}
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextUppercaseAndSpacing displayedText={"Unsere Partner"} />

                    <Link
                      href={"https://www.claussen-simon-stiftung.de/de/"}
                      title={
                        "Dieser Link führt zur Website der Claussen-Simon-Stiftung"
                      }
                    >
                      <img
                        src={LOGO_CLAUSSEN_SIMON_STIFTUNG}
                        alt={"Logo der Claussen-Simon-Stiftung"}
                        style={{ height: "3em", width: "auto" }}
                      />
                    </Link>

                    <Link
                      href={"https://www.schwartz-steuerberatung.de/"}
                      title={
                        "Dieser Link führt zur Website der Steuerberatung Schwartz"
                      }
                    >
                      <img
                        src={LOGO_STEUERBERATUNG_SCHWARZ}
                        alt={"Logo der Steuerberatung Schwartz"}
                        style={{ height: "3.5em", width: "auto" }}
                      />
                    </Link>

                    <Link
                      href={"https://www.kipitan.de/"}
                      title={
                        "Dieser Link führt zur Website von Kipitan (Unterstützung beim Logo- & Website-Design)"
                      }
                    >
                      <img
                        src={LOGO_KIPITAN}
                        alt={"Logo von Kipitan"}
                        style={{ height: "2em", width: "auto" }}
                      />
                    </Link>
                  </Stack>
                </>
              )}
            </Stack>
            <Stack spacing={2}>
              {!isReducedFooter && (
                <Box>
                  <TextUppercaseAndSpacing displayedText={"Kontakt"} />
                  <Link
                    href="mailto:info@chancenbuddy.de"
                    fontWeight="bold"
                    underline="none"
                    color="inherit"
                    title={
                      "Dieser Link öffnet einen Mailentwurf der an unserer Kontaktemail adressiert ist"
                    }
                  >
                    info@chancenbuddy.de
                  </Link>
                </Box>
              )}
            </Stack>

            {isReducedFooter && <Divider />}
            <Typography
              onClick={() => handleLoginClicked()}
              sx={{
                cursor: "pointer",
                "&:hover": { cursor: "pointer" },
                pt: 3,
              }}
            >
              Login
            </Typography>

            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1">
                  © 2024 Chancenbuddy gemeinnützige UG (haftungsbeschränkt)
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  <LinkColorless
                    href={"impressum"}
                    displayText={"Impressum"}
                    title={
                      "Dieser Link führt zum Impressum der Chancenbuddy gUG"
                    }
                  />
                  &nbsp;|&nbsp;
                  <LinkColorless
                    href={"datenschutzerklaerung"}
                    displayText={"Datenschutzerklärung"}
                    title={
                      "Dieser Link führt zur Datenschutzerklärung der Chancenbuddy gUG"
                    }
                  />
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
